import { render, staticRenderFns } from "./FooterWeb.vue?vue&type=template&id=8a076752&scoped=true"
import script from "./FooterWeb.vue?vue&type=script&lang=ts"
export * from "./FooterWeb.vue?vue&type=script&lang=ts"
import style0 from "./FooterWeb.vue?vue&type=style&index=0&id=8a076752&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a076752",
  null
  
)

export default component.exports